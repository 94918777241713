@font-face {
  font-family: 'lato';
  src: url(./assets/fonts/lato/regular.ttf) format('truetype');
}
@font-face {
  font-family: 'lato';
  font-weight: 500;
  src: url(./assets/fonts/lato/bold.ttf) format('truetype');
}
@font-face {
  font-family: 'lato';
  font-style: italic;
  src: url(./assets/fonts/lato/italic.ttf) format('truetype');
}

@font-face {
  font-family: 'mulish';
  src: url(./assets/fonts/mulish/regular.ttf) format('truetype');
}
@font-face {
  font-family: 'mulish';
  font-weight: 500;
  src: url(./assets/fonts/mulish/medium.ttf) format('truetype');
}
@font-face {
  font-family: 'mulish';
  font-style: italic;
  src: url(./assets/fonts/mulish/italic.ttf) format('truetype');
}